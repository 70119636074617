import { getBudgetRangeByAmountInPennies } from "../../../common/utils/budgetRanges"

const STRIPE_SINGLE_LEAD_PAYMENT_BASE = 'SingleLeadPayment'
const STRIPE_SUBSCRIPTION_BASE = 'UnlimitedLeadSubscription'

const getDynamicPricingProductId = (budgetRangeInPennies: string) => {
  const budgetRangeCategory = getBudgetRangeByAmountInPennies(Number(budgetRangeInPennies))
  const stripePayPerTenderProductId = `${STRIPE_SINGLE_LEAD_PAYMENT_BASE}${budgetRangeCategory}`
  const stripeSubscriptionProductId = `${STRIPE_SUBSCRIPTION_BASE}${budgetRangeCategory}`

  return { stripePayPerTenderProductId, stripeSubscriptionProductId }
}

export default getDynamicPricingProductId
