import createTheme from "@mui/material/styles/createTheme"

export const theme = createTheme({
  palette: {
    primary: {
      main: '#007367',
    },
    background:{
      default: '#f2f4f7',
    },
  },
})
