import { ContractorProfile } from "../../domains/projects/dialogs/BuilderSuggestionList"
import { Project } from "../../domains/projects/types"
import { BudgetCategory, BudgetRange } from "../../graphql/generated"
import { makeMoneyFlexible, moneyAmountAsNumber, MoneyRange } from "./currency"
import { alwaysArray } from "./data"
import { getEnumValueFromString } from "./enum"
import { objectEntries } from "./objects"

/**
 * Product talk about budgets in "kilo-pounds" (a non-SI unit) and the product processes currency in pence.
 * This function converts from marketing speak into data for storage / comparision.
 *
 * e.g. £150k > 150_000_00
 *
 * @param {number} kPounds The amount to convert in thousands of pounds (e.g. `£150k` or `£150,000` would be `150`)
 * @returns kPounds in pence
 */
const kPoundsToPence = (kPounds: number) => kPounds * 1_000_00

type BudgetRangeConfig = {
  fromInclusive: number,
  toExclusive: number,
}

// Get the enum value for the BudgetCategory
export const flexPercentages: Record<BudgetCategory, number> = {
  Exact: 0,       // 0%
  RoughIdea: 10,  // 10%
  NoIdea: 20,     // 20%
}

export const flexPercentages_v1: Record<BudgetCategory, number> = {
  Exact: 15,       // 15%
  RoughIdea: 30,  // 30%
  NoIdea: 50,     // 50%
}

export const budgetRangesConfigs: Record<BudgetRange, BudgetRangeConfig> = {
  F30T100: {
    fromInclusive: kPoundsToPence(30),
    toExclusive: kPoundsToPence(100),
  },
  F100T500: {
    fromInclusive: kPoundsToPence(100),
    toExclusive: kPoundsToPence(500),
  },
  F500T1000: {
    fromInclusive: kPoundsToPence(500),
    toExclusive: kPoundsToPence(1000),
  },
  F1000T3000: {
    fromInclusive: kPoundsToPence(1000),
    toExclusive: kPoundsToPence(3000),
  },
}

export const budgetRangeMatchesAmountInPennies = (amountInPennies: number, config: BudgetRangeConfig) =>
  amountInPennies >= config.fromInclusive && amountInPennies < config.toExclusive

export const getBudgetRangeByAmountInPennies = (amountInPennies?: number): BudgetRange | undefined => {
  if (amountInPennies == null) return amountInPennies

  const budgetRangeEntry = objectEntries(budgetRangesConfigs).find(([ , budgetRangeConfig ]) => budgetRangeMatchesAmountInPennies(amountInPennies, budgetRangeConfig))

  return budgetRangeEntry === undefined
    ? undefined
    : budgetRangeEntry[0]
}

const getFlexibleProjectBudgetAsMoneyRange = (projectOrLead: Project, expandProjectCreationFlowFlag: boolean, adjustFlexBudgetFlag = false): MoneyRange => {
  const budgetCategory = getEnumValueFromString(BudgetCategory, projectOrLead.budgetCategory)
  if (!budgetCategory) throw new Error(`Unknown BudgetCategory: ${projectOrLead.budgetCategory}`)

  const flexPercentage = expandProjectCreationFlowFlag ? flexPercentages_v1[budgetCategory] : flexPercentages[budgetCategory]

  // Show the appropriate level of accuracy
  return makeMoneyFlexible(projectOrLead.budgetValue, flexPercentage, adjustFlexBudgetFlag)
}

type BudgetRangeSubscriptions = Pick<ContractorProfile, 'budgetRangeSubscriptions'>

// The ContractorProfile_ShouldPayForLead type is used instead of ContractorProfile because when the contractor comes from the matching api
//it only contains information about the budget ranges.
type ContractorProfile_ShouldPayForLead = {
  budgetRangeSubscriptions?: Pick<NonNullable<BudgetRangeSubscriptions['budgetRangeSubscriptions']>[number], 'budgetRange'>[] | null | undefined,
}

export const shouldPayForLead = (projectOrLead: Project, contractorProfile: ContractorProfile_ShouldPayForLead, expandProjectCreationFlowFlag = false, adjustFlexBudgetFlag = false) => {
  // Fetch the MoneyRange and workout the overlapping BudgetRanges
  const leadMoneyRange = getFlexibleProjectBudgetAsMoneyRange(projectOrLead, expandProjectCreationFlowFlag, adjustFlexBudgetFlag)
  const leadMoneyRangeBudgetRanges = getBudgetRangesByMoneyRange(leadMoneyRange)
  // Grab the BudgetRanges the contractor is subscribed to
  const subscribedBudgetRanges = alwaysArray(contractorProfile.budgetRangeSubscriptions).map(({ budgetRange }) => budgetRange)

  // Do any of the leadMoneyRangeBudgetRanges match the subscribedBudgetRanges
  const hasMatchingSubscription = alwaysArray(leadMoneyRangeBudgetRanges).some(budgetRange => subscribedBudgetRanges.includes(budgetRange))

  return !hasMatchingSubscription
}

export const getBudgetRangesByMoneyRange = (moneyRange?: MoneyRange): BudgetRange[] | undefined => {
  if (moneyRange == null) return moneyRange

  const moneyRangeTopPence = moneyAmountAsNumber(moneyRange.rangeTop) ?? Number.MAX_VALUE
  const moneyRangeBottomPence = moneyAmountAsNumber(moneyRange.rangeBottom) ?? 0

  return Object.values(BudgetRange)
    .filter(budgetRange => {
      const budgetRangeTop = budgetRangesConfigs[budgetRange].toExclusive
      const budgetRangeBottom = budgetRangesConfigs[budgetRange].fromInclusive

      // When the leadBudgetRange overlaps partially within a subscription budget range
      // Match if the top OR bottom of the lead is between the subscription budget range top AND bottom
      if (moneyRangeTopPence >= budgetRangeBottom && moneyRangeTopPence <= budgetRangeTop) return true
      if (moneyRangeBottomPence >= budgetRangeBottom && moneyRangeBottomPence <= budgetRangeTop) return true

      // Match if the bottom of the lead is below the bottom of the the subscription budget range AND
      // the top of the lead is also above the top of the the subscription budget range
      // This is when the subscriptions fall wholly within the leadBudgetRange
      if (moneyRangeBottomPence < budgetRangeBottom && moneyRangeTopPence > budgetRangeTop) return true

      return false
    })
}
