/* eslint-disable react/jsx-key */
import { Box, Button, Chip, Stack, Typography } from "@mui/material"
import { CellContext, createColumnHelper, flexRender, getCoreRowModel, RowData, useReactTable } from "@tanstack/react-table"
import AddIcon from "@mui/icons-material/Add"
import { useState } from "react"
import { BuildersSearchRecord, ExtendedItemStructure } from "../../../../common/hooks/useMatchApi"
import Label from "./Label"
import WeightLabel from "./WeightLabel"
import { BudgetRange } from "../../../../graphql/generated"
import { getEnumValueFromString } from "../../../../common/utils/enum"
import { isSomething } from "../../../../common/utils/data"

// This is how we define the structure of the react-table meta
// https://tanstack.com/table/v8/docs/api/core/table#meta
declare module '@tanstack/react-table' {
  interface TableMeta<TData extends RowData> {
    onInviteClick: (selectedContractorIndex: number) => void,
  }
}

const convertValue = (value?: string | null | number) => {
  if (typeof value === 'number') {
    return value
  }
  if (value === null || typeof value !== 'string') {
    return 0
  }
  return parseFloat(value)
}

const intlCurrencyFormatter = (value: string | number | null) => new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(convertValue(value)/100)

const genericCell = <T extends ExtendedItemStructure>(props: CellContext<BuildersSearchRecord, T>) => <><WeightLabel error={props.getValue().error}>{props.getValue().weight}</WeightLabel> {props.getValue().value}</>

const monetaryCell = <T extends ExtendedItemStructure>(props: CellContext<BuildersSearchRecord, T>) => <><WeightLabel error={props.getValue().error}>{props.getValue().weight}</WeightLabel> {intlCurrencyFormatter(props.getValue().value)}</>

const defaultColumnDef = {
  size: 300,
  maxSize: 480,
}

const columnHelper = createColumnHelper<BuildersSearchRecord>()

const defaultColumns = [
  columnHelper.accessor('total_weight', {
    header: 'Total Weight',
    ...defaultColumnDef,
  }),
  columnHelper.accessor('team_id.value', {
    header: 'Rank',
    size: 50,
    cell: (props) => {
      return props.row.index + 1
    },
  }),
  columnHelper.accessor('name.value', {
    header: 'Name',
    size: 400,
    maxSize: 500,
  }),
  columnHelper.accessor('locations', {
    header: 'Locations',
    size: 400,
    maxSize: 600,
    cell: (props) => {
      const locations = props.getValue()
      return <>
        <WeightLabel error={props.getValue().error}>{locations.weight}</WeightLabel>
        {(locations.value ?? []).map((l, i) => <Chip key={`${i}`} label={l?.toString()} />)}
      </>
    },
  }),
  columnHelper.accessor('company_status', {
    header: 'Company status',
    ...defaultColumnDef,
    cell: genericCell,
  }),
  columnHelper.accessor('company_status_readable', {
    header: 'Company status readable',
    ...defaultColumnDef,
    cell: genericCell,
  }),
  columnHelper.accessor('vetting_status', {
    header: 'Vetting status',
    ...defaultColumnDef,
    cell: genericCell,
  }),
  columnHelper.accessor('vetting_status_readable', {
    header: 'Vetting status readable',
    ...defaultColumnDef,
    cell: genericCell,
  }),
  columnHelper.accessor('budget_ranges', {
    header: 'Budget ranges',
    ...defaultColumnDef,
    cell: (props) => {
      return <>
        <WeightLabel error={props.getValue().error}>{props.getValue().weight}</WeightLabel>
        <Stack direction="row" spacing={1}>
          {(props.getValue().value || []).map(({ budget_range, status, stripe_status }: { budget_range: string, status: boolean, stripe_status: string | null }) => (
            <Chip
              key={`${budget_range}-${props.row.id}`}
              label={budget_range}
              color={status
                ? stripe_status === "canceled"
                  ? "error"
                  : "success"
                : "warning"}
              icon={status ? <>{stripe_status === "active"
                ? <Label>Active</Label>
                : stripe_status === "trialing"
                  ? <Label>Trialing</Label>
                  : stripe_status === "canceled"
                    ? <Label>Cancelled</Label>
                    : <Label>No subscription</Label>
              }</> : <Label>Unknown</Label> }
            />
          ))}
        </Stack>
      </>
    },
  }),
  columnHelper.accessor('work_history', {
    header: 'Work history',
    ...defaultColumnDef,
    cell: (props) => {
      return <>
        <WeightLabel error={props.getValue().error}>{props.getValue().weight}</WeightLabel>
        {props.getValue().value.map((wh, i) => {
          const hasCompleted = !!(wh.references && wh.references.find(r => r.status === 'Completed'))
          return <Box key={`${props.row.id}-wh-${i}`} p="5px" border="1px solid gray" borderRadius="5px" m="5px" bgcolor={hasCompleted ? '#2e7d32' : 'white'} color={hasCompleted ? 'white' : 'black'}>
            <Typography>Project types: {wh.projectTypes?.join(', ')}</Typography>
            <Typography>Construction value: {intlCurrencyFormatter(wh.constructionValue?.amountInPence || 0)}</Typography>
            <Stack direction="row" alignItems="center" gap="5px">
              <Typography>References:</Typography>
              {wh.references ? wh.references.map((r, ri) => (
                <Chip key={`${props.row.id}-wh-${i}-chip-${ri}`} label={`Status: ${r.status}`} icon={<>{r.teamType.charAt(0)}</>} />
              )) : <Chip label="None" />}
            </Stack>
          </Box>
        })}
      </>
    },
  }),
  columnHelper.accessor('invites_sent', {
    header: 'Tenders sent',
    ...defaultColumnDef,
    cell: (props) => {
      return <>
        <WeightLabel error={props.getValue().error}>{props.getValue().weight}</WeightLabel>
        {Object.entries(props.getValue().value).map(([ budgetRange, invite ], i) => (
          <Chip key={`${props.row.id}-leads-sent-${i}`} label={budgetRange} icon={<Label>{invite.length}</Label>} />
        ))}
      </>
    },
  }),
  columnHelper.accessor('invites_accepted', {
    header: 'Tenders accepted',
    ...defaultColumnDef,
    cell: (props) => {
      return <>
        <WeightLabel error={props.getValue().error}>{props.getValue().weight}</WeightLabel>
        {Object.entries(props.getValue().value).map(([ budgetRange, invite ], i) => (
          <Chip key={`${props.row.id}-leads-accepted-${i}`} label={budgetRange} icon={<Label>{invite.length}</Label>} />
        ))}
      </>
    },
  }),
  columnHelper.accessor('invites_rejected', {
    header: 'Tenders rejected',
    ...defaultColumnDef,
    cell: (props) => {
      return <>
        <WeightLabel error={props.getValue().error}>{props.getValue().weight}</WeightLabel>
        {Object.entries(props.getValue().value).map(([ budgetRange, invite ], i) => (
          <Chip key={`${props.row.id}-leads-rejected-${i}`} label={budgetRange} icon={<Label>{invite.length}</Label>} />
        ))}
      </>
    },
  }),
  columnHelper.accessor('total_spent', {
    header: 'Total Spent',
    ...defaultColumnDef,
    cell: monetaryCell,
  }),
  columnHelper.accessor('total_refunded', {
    header: 'Total Refunded',
    ...defaultColumnDef,
    cell: monetaryCell,
  }),
  columnHelper.accessor('payment_email', {
    header: 'Payment Email',
    ...defaultColumnDef,
    cell: genericCell,
  }),
  columnHelper.accessor('email', {
    header: 'Email',
    ...defaultColumnDef,
    cell: genericCell,
  }),
  columnHelper.accessor('emails_sent', {
    header: 'Emails Sent',
    ...defaultColumnDef,
    cell: genericCell,
  }),
  columnHelper.accessor('links_clicked', {
    header: 'Links Clicked',
    ...defaultColumnDef,
    cell: genericCell,
  }),
  columnHelper.accessor('sms_sent', {
    header: 'SMS Sent',
    ...defaultColumnDef,
    cell: genericCell,
  }),
  columnHelper.accessor('team_id.value', {
    header: 'Actions',
    id: 'actions',
    ...defaultColumnDef,
    cell: (props) => <Button onClick={() => {
      return props.table.options.meta?.onInviteClick(props.row.index)}
    } variant='outlined' color="success"><AddIcon />Invite</Button>,
  }),
]

const Table = ({ data, onInviteClick }: { data: BuildersSearchRecord[], onInviteClick: (teamId: string, budget_ranges: BudgetRange[]) => void }) => {
  const [ columns ] = useState<typeof defaultColumns>(() => [
    ...defaultColumns,
  ])

  const columnResizeMode = 'onEnd'
  const table = useReactTable({
    data,
    columns,
    columnResizeMode,
    getCoreRowModel: getCoreRowModel(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: true,
    meta: {
      onInviteClick: (selectedContractorIndex: number) => {
        const contractor = data[selectedContractorIndex]
        const budgetRanges = contractor.budget_ranges.value.map(({ budget_range }: { budget_range: string }) => getEnumValueFromString(BudgetRange, budget_range))
        return onInviteClick( contractor.team_id.value , budgetRanges.filter(isSomething))
      },
    },
  })

  return <table
    {...{
      className: 'matchingTable',
      style: {
        width: table.getCenterTotalSize(),
      },
    }}
  >
    <thead>
      {table.getHeaderGroups().map(headerGroup => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map(header => (
            <th
              {...{
                key: header.id,
                colSpan: header.colSpan,
                style: {
                  width: header.getSize(),
                },
              }}
            >
              {header.isPlaceholder
                ? null
                : flexRender(
                  header.column.columnDef.header,
                  header.getContext(),
                )}
              <div
                {...{
                  onMouseDown: header.getResizeHandler(),
                  onTouchStart: header.getResizeHandler(),
                  className: `resizer ${
                    header.column.getIsResizing() ? 'isResizing' : ''
                  }`,
                  style: {
                    transform:
                      columnResizeMode === 'onEnd' &&
                      header.column.getIsResizing()
                        ? `translateX(${
                          table.getState().columnSizingInfo.deltaOffset
                        }px)`
                        : '',
                  },
                }}
              />
            </th>
          ))}
        </tr>
      ))}
    </thead>
    <tbody>
      {table.getRowModel().rows.map(row => (
        <tr key={row.id}>
          {row.getVisibleCells().map(cell => (
            <td
              {...{
                key: cell.id,
                style: {
                  width: cell.column.getSize(),
                },
              }}
            >
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </td>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
}

export default Table
