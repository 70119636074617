import React from 'react'
import { Button, Grid  } from '@mui/material'
import { useCoreApiSource } from '../../common/hooks/useCoreApiSource'
import { TeamType, useListAllPartnerProjectsQuery } from '../../graphql/generated'
import { ProjectMember } from '../projects/types'
import { DateTime } from 'luxon'
import { json2csv } from 'json-2-csv'

export const ReportsIndex: React.FC = () => {

  const gqlDatasource = useCoreApiSource()
  const listAllPartnerProjects = useListAllPartnerProjectsQuery(gqlDatasource)

  const getMemberJoinedDate = (members: ProjectMember[], memberTeamType: TeamType) => {
    const member = members.find(member => member.team.type === memberTeamType)
    if (!member?.createdAt) return `No ${memberTeamType} has joined`

    if (memberTeamType === TeamType.Contractor) {
      const contractorJoinedDates = members.filter(member => member.team.type === memberTeamType).map(member => member.createdAt)
      const sortedDatesByEarlierstFirst = contractorJoinedDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())

      return DateTime.fromISO(sortedDatesByEarlierstFirst[0]).toFormat('dd/MM/yyyy')
    }

    return DateTime.fromISO(member?.createdAt).toFormat('dd/MM/yyyy')
  }

  const getPartnerTeamName = (members: ProjectMember[]) => {
    const member = members.find(member => member.team.type === TeamType.Partner)
    return member?.team.name ?? ''
  }

  const data = listAllPartnerProjects.data?.listAllPartnerProjects

  if (listAllPartnerProjects.isLoading) return <div>Loading...</div>

  const csvData = data?.map((project) => {
    return {
      'Project Address': project.address?.addressLine1,
      'Project created date': DateTime.fromISO(project.createdAt).toFormat('dd/MM/yyyy'),
      'First name': project.partnerFirstName,
      'Last Name': project.partnerLastName,
      'Homeowner joined date': getMemberJoinedDate(project.members, TeamType.Homeowner),
      'Weaver contractor matched date': getMemberJoinedDate(project.members, TeamType.Contractor), // First contractor to join date
      'Referral qualified at': project.partnerReferralStatus?.createdAt ? DateTime.fromISO(project.partnerReferralStatus?.createdAt).toFormat('dd/MM/yyyy') : 'Not qualified',
      'Partner': getPartnerTeamName(project.members),
    }
  })

  const handleExportButton = async () => {
    if (!csvData) return console.error('No data to export')
    const csvReport = await json2csv(csvData, {
      trimFieldValues: true,
    })

    const fileName = `Partner-Report-${DateTime.now().toFormat('dd-MM-yyyy')}.csv`

    const vLink = document.createElement('a')
    const vBlob = new Blob([ csvReport ], { type: "text/csv" })
    const vUrl = window.URL.createObjectURL(vBlob)
    vLink.setAttribute('href', vUrl)
    vLink.setAttribute('download', fileName)
    vLink.click()
  }

  return (
    <>
      <Grid item xs={6} sx={{ display: "flex", alignItems:"center", padding: "1rem" }}>
        <Button color='primary' variant='contained' onClick={handleExportButton}>
          <p>Export Partner CSV</p>
        </Button>
      </Grid>
    </>
  )
}
