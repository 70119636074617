import { Link } from '@mui/material'
import path from 'path-browserify'
import React, { useMemo } from 'react'
import { EnvContext } from '../../Bootstrapper'
import { useRequiredContext } from '../../common/hooks/useRequiredContext'
import { ContractorProfile } from './types'

type BuilderSummaryProps = {
  contractorProfile : ContractorProfile,
}

export const BuilderSummary: React.FC<BuilderSummaryProps> = ({ contractorProfile }) => {
  const { REACT_APP_APP_URL } = useRequiredContext(EnvContext)

  if (!contractorProfile.id) throw new Error('Contractor id is missing')

  const contractorProfileUrl = useMemo(() => {
    const url = new URL(REACT_APP_APP_URL)
    url.pathname = path.join("/profile/CONTRACTOR", contractorProfile.id)
    return url
  }, [ REACT_APP_APP_URL, contractorProfile.id ])

  return (
    <div>
      <Link target='_blank' href={contractorProfileUrl.href}>{contractorProfile.companyTradingAs || contractorProfile.companyRegisteredName}</Link>
    </div>
  )
}
