import React, { useMemo } from 'react'
import { ListAllProjectsQuery, TaskStatus } from '../../../graphql/generated'

import SquareIcon from '@mui/icons-material/Square'
import SquareOutlinedIcon from '@mui/icons-material/SquareOutlined'
import Tooltip from '@mui/material/Tooltip'

type TaskStatusProps = {
  project: ListAllProjectsQuery["listAllProjects"]["data"][number],
}
const TaskStatusDisplay: React.FC<TaskStatusProps> = ({ project }) => {
  const tasks = useMemo(() => project.tasks.sort((a,b) => a.order - b.order), [ project.tasks ])

  return (
    <div>
      { tasks.length < 1
        ? "No Tasks"
        : tasks.map(x => {
          if (x.activeStatus == TaskStatus.NotStarted) {
            return <Tooltip key={x.id} title={`${x.title}: Not Started`}><SquareOutlinedIcon key={x.id} color='action' /></Tooltip>
          } else if (x.activeStatus === TaskStatus.Completed) {
            return <Tooltip key={x.id} title={`${x.title}: Completed`}><SquareIcon key={x.id} color='success' /></Tooltip>
          } else if (x.activeStatus === TaskStatus.Overdue) {
            return <Tooltip key={x.id} title={`${x.title}: Overdue`}><SquareIcon key={x.id} color='error' /></Tooltip>
          } else if (x.activeStatus === TaskStatus.InProgress) {
            return <Tooltip key={x.id} title={`${x.title}: In Progress`}><SquareIcon key={x.id} color='warning' /></Tooltip>
          } else {
            return <Tooltip key={x.id} title={`${x.title}: Unknown`}><SquareIcon key={x.id} color='action' /></Tooltip>
          }
        })}
    </div>
  )
}

export default TaskStatusDisplay
