import Button from '@mui/material/Button'
import { DataGrid } from '@mui/x-data-grid/DataGrid'
import { GridColDef } from '@mui/x-data-grid/models/colDef'
import React, { ComponentProps, useCallback, useMemo, useState } from 'react'
import { useCoreApiSource } from '../../../common/hooks/useCoreApiSource'
import { BudgetRange, ContractorProgress, ListContractorSuggestionsForProjectQuery, ProjectMemberRole, UploadedFileStatus, useAddProjectInviteMutation, useGetStripeAvailablePricesByProductIdQuery, useListContractorSuggestionsForProjectQuery } from '../../../graphql/generated'
import AddIcon from "@mui/icons-material/Add"
import BusinessCenterRoundedIcon from '@mui/icons-material/BusinessCenterRounded'
import { InviteTeamToProjectState, Project } from '../types'
import { useRequiredContext } from '../../../common/hooks/useRequiredContext'
import { ProjectsIndexContext } from '../ProjectsIndexContext'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Box from '@mui/material/Box'
import Styles from "./BuilderSuggestionList.module.scss"
import Tooltip from '@mui/material/Tooltip'
import { EnvContext } from '../../../Bootstrapper'
import { CircularProgress, Dialog, DialogActions, Link, MenuItem, Select, Stack, Tab, Tabs } from '@mui/material'
import { useAnalyticsEvent } from '../../../common/hooks/analytics'
import { useBuildersSearchMutate, useMatchApiDataSource } from '../../../common/hooks/useMatchApi'
import RequestInput, { RequestInputOnSubmit } from './MatchingDashboard/RequestInput'
import { useWeaverFlags } from '../../../api/thirdparty/launchDarkly/useWeaverFlags'
import { getBudgetRangeByAmountInPennies, shouldPayForLead } from '../../../common/utils/budgetRanges'
import Table from './MatchingDashboard/Table'
import './MatchingDashboard/Table.css'

import { ContractorStatusChip } from '../../../common/components/Status/ContractorStatusChip'
import { useFindContractorStatus } from '../../../common/hooks/useFindContractorStatus'
import { alwaysArray } from '../../../common/utils/data'
import getDynamicPricingProductId from './getBudgetRangeDyanmicPriceOptions'
import { currencySymbols } from '../../../common/utils/currency.i18n'

export type ContractorProfile = ListContractorSuggestionsForProjectQuery["listContractorSuggestionsForProject"][0];

type BuilderSuggestionActionButtonProps = {
  profile: ContractorProfile,
  project: Project,
  onClick: (team: ContractorProfile, budgetRangeSubscriptions: BudgetRange[] | undefined, shouldContractorPayForLead: boolean) => unknown | void,
} & Omit<ComponentProps<typeof Button>, "onClick">

const DynamicPricingSelectStyles = {
  width: '100%',
  marginTop: '1rem',
  height: '2.5rem',
}

const BuilderSuggestionActionButton: React.FC<BuilderSuggestionActionButtonProps> = ({ onClick, profile, project, ...buttonProps }) => {
  const { ["MW-2414-prevent-sinbin-invites"]: preventSinbinInvites, ["MW-2600-expand-project-creation-flow"]: expandProjectCreationFlowFlag, ["MW-2645-adjust-flex-budget-ranges"]: adjustFlexBudgetFlag } = useWeaverFlags()
  const status = useFindContractorStatus(profile.statusId)
  const [ confirming, setConfirming ] = useState(false)

  const budgetRangeSubscriptions = profile.budgetRangeSubscriptions?.map(({ budgetRange }) => budgetRange)
  const shouldContractorPayForLead = shouldPayForLead(project, profile, expandProjectCreationFlowFlag, adjustFlexBudgetFlag)

  const requiresConfirmation = preventSinbinInvites && status.data && status.data.progress === ContractorProgress.Fail

  const handleInvite = useCallback(() => onClick(profile, budgetRangeSubscriptions, shouldContractorPayForLead), [ onClick, profile ])
  const handleConfirm = useCallback(() => setConfirming(true), [ setConfirming ])
  const handleConfirmCancel = useCallback(() => setConfirming(false), [ setConfirming ])
  const handleConfirmSuccess = useCallback(async () => {
    await handleInvite()
    setConfirming(false)
  }, [ setConfirming, handleInvite ])

  const handleClick = requiresConfirmation ? handleConfirm : handleInvite
  return <>
    <Button variant='outlined' color={"success"} onClick={handleClick} {...buttonProps}><AddIcon />Invite</Button>
    {
      requiresConfirmation
        ? <Dialog
          open={confirming}
          onClose={handleConfirmCancel}
        >
          <DialogContent>
            <Typography>
              {profile.companyTradingAs || profile.companyRegisteredName} is in a failed status, are you sure you want to invite them?
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button>Dismiss</Button>
            <Button variant='outlined' color="success" onClick={handleConfirmSuccess} {...buttonProps}><AddIcon />Invite</Button>
          </DialogActions>
        </Dialog>
        : null
    }
  </>
}

type BuilderSuggestionListProps = {
  modalState: InviteTeamToProjectState,
  onHandleClose?: () => void,
}

interface TabPanelProps {
  children?: React.ReactNode,
  index: number,
  value: number,
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 3 }}>{children}</Box>
      )}
    </div>
  )
}

const BuilderSuggestionName: React.FC<{contractorProfile: ContractorProfile}> = ({ contractorProfile }) => {
  const name = contractorProfile.companyTradingAs || contractorProfile.companyRegisteredName
  const { REACT_APP_APP_URL } = useRequiredContext(EnvContext)

  const contractorUrl = `${REACT_APP_APP_URL}/profile/CONTRACTOR/${contractorProfile.id}`
  return (<Box>
    <Typography><Link target="_blank" href={contractorUrl}>{name}</Link></Typography>
    {contractorProfile.companyNumber &&
      <Tooltip title="Company Number">
        <Typography className={Styles.secondaryInfo} variant="body2" color={"text.secondary"} >
          <BusinessCenterRoundedIcon />{contractorProfile.companyNumber}
        </Typography>
      </Tooltip>
    }
  </Box>)
}

const BuilderSuggestionList: React.FC<BuilderSuggestionListProps> = ({ modalState: { project }, onHandleClose }) => {
  const { ["MW-2414-prevent-sinbin-invites"]: preventSinbinInvites, ['MW-2640-ops-dashboard-dynamic-pricing']: dynamicPricingInvitesFlag, ["MW-2645-adjust-flex-budget-ranges"] : adjustFlexBudgetFlag  } = useWeaverFlags()
  const gqlDatasource = useCoreApiSource()
  const matchApiDataSource = useMatchApiDataSource()
  const { stripePayPerTenderProductId, stripeSubscriptionProductId } = getDynamicPricingProductId(project.budgetValue.amountInPence)
  const productPricesResponse = useGetStripeAvailablePricesByProductIdQuery(gqlDatasource, { payPerTenderProductId: stripePayPerTenderProductId, subscriptionProductId: stripeSubscriptionProductId })
  const { handleModalSuccess } = useRequiredContext(ProjectsIndexContext)
  const [ dynamicPayPerTenderPriceActiveOption, setDynamicPayPerTenderPriceActiveOption ] = useState<string | undefined>(undefined)
  const [ dynamicSubscriptionPriceActiveOption, setDynamicSubscriptionPriceActiveOption ] = useState<string | undefined>(undefined)
  const [ invitedTeams, setInvitedTeams ] = useState<string[]>([])
  const [ value, setValue ] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(parseInt(newValue))
  }

  const activeDocumentPreviewCount = project.documentPreviews?.filter(p => p.status !== UploadedFileStatus.Archived).length ?? 0
  const activeDocuments = project.documents?.filter(d => d.status !== UploadedFileStatus.Archived) ?? []
  const allDocumentTags = activeDocuments?.filter(d => d.kind !== null).map(d => d.kind).flat() ?? []

  const searchBuilders = useBuildersSearchMutate(matchApiDataSource)

  const exactBudgetRangeOfProject = getBudgetRangeByAmountInPennies(Number(project.budgetValue.amountInPence))

  const query = useListContractorSuggestionsForProjectQuery(gqlDatasource, { projectId: project.id }, { refetchOnWindowFocus: false })
  const addProjectInvite = useAddProjectInviteMutation(gqlDatasource)

  const getDynamicPricingDataForProduct = (productPrices: {priceId: string, price: number, priceLabel: string}[], activePriceOption: string | undefined) => {
    return productPrices.find(price => price.priceId === activePriceOption)
  }

  const fireInviteMutationAndEvent = async (team: Pick<ContractorProfile, 'id'>, budgetRangeSubscriptions: BudgetRange[] | undefined, shouldContractorPayForLead: boolean, dynamicPayPerTenderPriceActiveOption: string | undefined, dynamicSubscriptionPriceActiveOption: string | undefined) => {
    const payPerTenderDynamicPricingData = productPricesResponse?.data?.getStripeAvailablePricesByProductId?.payPerTender
    const subscriptionDynamicPricingData = productPricesResponse?.data?.getStripeAvailablePricesByProductId?.subscription

    const payPerTenderSelectedPricingData = payPerTenderDynamicPricingData ? getDynamicPricingDataForProduct(payPerTenderDynamicPricingData, dynamicPayPerTenderPriceActiveOption) : undefined
    const subscriptionSelectedDynamicPricingData = subscriptionDynamicPricingData ? getDynamicPricingDataForProduct(subscriptionDynamicPricingData, dynamicSubscriptionPriceActiveOption) : undefined

    await addProjectInvite.mutateAsync({
      projectId: project.id,
      teamId: team.id,
      projectMemberRole: ProjectMemberRole.CandidateProfessional,
      priceTier: dynamicPricingInvitesFlag // FLAG MW-2640
        ? {
          payPerTender: payPerTenderSelectedPricingData,
          subscription: subscriptionSelectedDynamicPricingData,
        }
        : undefined,
    },
    {
      onSuccess: async (data, variables) => {
        await fireEvent_Ops_Invite_Sent({
          teamId: variables.teamId,
          projectId: project.id,
          types: project.projectTypes,
          description: project.description,
          currency: project.budgetValue.currency,
          budgetInPence: project.budgetValue.amountInPence,
          budgetCategory: project.budgetCategory,
          budgetRange: exactBudgetRangeOfProject,
          budgetRangeSubscriptions: budgetRangeSubscriptions,
          shouldPayForLead: shouldContractorPayForLead,
          workStartEstimate: project.workStartEstimate,
          tenderReturnDate: undefined,
          documentPreviewCount: activeDocumentPreviewCount,
          documentCount: activeDocuments.length,
          allDocumentTags: alwaysArray(allDocumentTags),
        })
        handleModalSuccess(project.id, false)
        setInvitedTeams((prev) => [ ...prev, team.id ])
      },
    })
  }

  const fireEvent_Ops_Invite_Sent = useAnalyticsEvent('Ops_Invite_Sent')

  const handleAdd = useCallback((team: Pick<ContractorProfile, 'id'>, budgetRangeSubscriptions: BudgetRange[] | undefined, shouldContractorPayForLead: boolean) => {
    if (dynamicPricingInvitesFlag){
      fireInviteMutationAndEvent(team, budgetRangeSubscriptions, shouldContractorPayForLead, dynamicPayPerTenderPriceActiveOption, dynamicSubscriptionPriceActiveOption)
    } else {
      fireInviteMutationAndEvent(team, budgetRangeSubscriptions, shouldContractorPayForLead, undefined, undefined)
    }
  }, [ dynamicPayPerTenderPriceActiveOption, dynamicSubscriptionPriceActiveOption ])

  const handleSubmit: RequestInputOnSubmit = (values) => {
    searchBuilders.mutate({
      ...values,
      from: new Date(values.from ?? '').toISOString(),
      to: new Date(values.to ?? '').toISOString(),
    })
  }

  const getPriceInPenniesAsCurrencyInPoundsWithLabel = (priceInPennies: number, priceLabel: string) => {
    return currencySymbols.GBP + (priceInPennies/ 100).toFixed(2) + '  -  ' + priceLabel
  }

  const productPricesSorted = (productPrices: {priceId: string, price: number, priceLabel: string}[]) => {
    return productPrices.sort((a, b) => {
      if (a.price < b.price) {
        return -1
      }
      if (a.price > b.price) {
        return 1
      }
      return 0
    })
  }

  const columns = useMemo<GridColDef<ContractorProfile>[]>(() => [
    { sortable: false, flex: 1, minWidth: 200, field: 'companyRegisteredName', headerName: 'Name', renderCell: (params) => <BuilderSuggestionName contractorProfile={params.row} /> },
    ...(preventSinbinInvites ? [ { sortable: false, maxWidth: 200, flex: 1, field: 'statusId', headerName: 'Status', renderCell: (params) => <ContractorStatusChip statusId={params.row.statusId} /> } as GridColDef<ContractorProfile> ] : []),
    { sortable: false, minWidth: 125, field: 'acceptedLeads', valueFormatter: (params) => (params.value || 0), headerName: 'Accepted Tenders' },
    { sortable: false, minWidth: 125, field: 'rejectedLeads', valueFormatter: (params) => (params.value || 0), headerName: 'Rejected Tenders' },
    { sortable: false, flex: 1, minWidth: 100, maxWidth: 150, field: 'actions', headerName: 'Actions', align: "right", renderCell: (params) => <BuilderSuggestionActionButton profile={params.row} project={project} onClick={handleAdd} disabled={addProjectInvite.isLoading} /> },
  ], [ handleAdd, addProjectInvite.isLoading, preventSinbinInvites ])

  const getStripeActivePriceForProduct = (productPrices: {priceId: string, price: number, priceLabel: string}[]) => {
    return productPrices.find(price => price.priceLabel === 'ActivePriceId')
  }

  const data = query.data?.listContractorSuggestionsForProject || []
  const matchingData = searchBuilders.data || []
  const payPerTenderProductPrices = productPricesResponse.data?.getStripeAvailablePricesByProductId?.payPerTender ?? []
  const subscriptionProductPrices = productPricesResponse.data?.getStripeAvailablePricesByProductId?.subscription ?? []
  const defaultActivePayPerTenderPrice = getStripeActivePriceForProduct(payPerTenderProductPrices)
  const defaultActiveSunscriptionTenderPrice = getStripeActivePriceForProduct(subscriptionProductPrices)

  /** HACK until the setQueryData update is working we will manage this state locally triggered by onSuccess of mutation */
  const filteredInvitedTeams = data.filter((team) => !invitedTeams.includes(team.id))

  return (
    <>
      <DialogTitle>
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CloseIcon sx={{ marginRight: '0.25rem', cursor: 'pointer' }} onClick={onHandleClose} />Invite Contractor to Project
          </Box>
          {dynamicPricingInvitesFlag
            ? productPricesResponse.isLoading
              ? <CircularProgress color="success" />
              : <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                <Box  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '55%', border: 'solid 2px black', p: 2 }}>
                  <Box sx={{ width: '47%' }}>
                    <Typography variant='body1'>Subscription price</Typography>
                    <Typography variant='caption' color={'text.secondary'}>Active Price ID is the default Stripe price</Typography>
                    <Select defaultValue={defaultActiveSunscriptionTenderPrice?.priceId} sx={DynamicPricingSelectStyles} onChange={(e) => setDynamicSubscriptionPriceActiveOption(e.target.value)}>
                      {subscriptionProductPrices && productPricesSorted(subscriptionProductPrices).map((priceOption) => (
                        <MenuItem key={priceOption.priceId} value={priceOption.priceId}>{getPriceInPenniesAsCurrencyInPoundsWithLabel(priceOption.price, priceOption.priceLabel)}</MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box sx={{ width: '47%' }}>
                    <Typography variant='body1'>PPT price</Typography>
                    <Typography variant='caption' color={'text.secondary'}>Active Price ID is the default Stripe price</Typography>
                    <Select defaultValue={defaultActivePayPerTenderPrice?.priceId} sx={DynamicPricingSelectStyles} onChange={(e) => setDynamicPayPerTenderPriceActiveOption(e.target.value)}>
                      {payPerTenderProductPrices && productPricesSorted(payPerTenderProductPrices).map((priceOption) => (
                        <MenuItem key={priceOption.priceId} value={priceOption.priceId}>{getPriceInPenniesAsCurrencyInPoundsWithLabel(priceOption.price, priceOption.priceLabel)}</MenuItem>
                      ))}
                    </Select>
                  </Box>
                </Box>
              </Box>
            : null
          }
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Matching API" />
            <Tab label="Suggestion list" />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <RequestInput onSubmit={handleSubmit} project={project} />
          {!!data.length && (
            <Stack overflow='auto' mt={2}>
              <Table data={matchingData} onInviteClick={(teamId: string, budgetRanges: BudgetRange[]) => {
                const budgetRangeSubscriptions = budgetRanges.map(budgetRange => {
                  return {
                    budgetRange,
                  }
                })

                const contractor = {
                  id: teamId,
                  budgetRangeSubscriptions,
                }
                const shouldContractorPayForLead = shouldPayForLead(project, contractor, undefined, adjustFlexBudgetFlag)
                handleAdd(contractor, budgetRanges, shouldContractorPayForLead)
              }
              } />
            </Stack>
          )}
        </TabPanel>
        <TabPanel value={value} index={1}>
          <DataGrid
            rows={filteredInvitedTeams}
            columns={columns}
            disableSelectionOnClick={true}
            autoHeight={true}
            disableColumnMenu
          />
        </TabPanel>
      </DialogContent>
    </>
  )
}

export default BuilderSuggestionList
